createEntryParams = function(param){
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('order',  param)
    var newParams = urlParams.toString()
    window.location = '?' + newParams;
}

changeDirection = function(name){
    const urlParams = new URLSearchParams(window.location.search);
    const direction = urlParams.get('direction')
    urlParams.set('order', name)
    console.log(direction)
    if(!direction || direction == "false"){
        urlParams.set('direction', true);
    } else {
        urlParams.set('direction', false);
    }
    var newParams = urlParams.toString()
    window.location = '?' + newParams;
}